import SignedAsHeader from '@components-simple/global-site-components/header/SignedAsHeader'
import PagePathConstant from '@lib/constants/page-path.constant'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Link from 'next/link'
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import SystemHelper from '@lib/helpers/system.helper'
import logger from 'logger'
import moment from 'moment'
import { useFetchElastioRegions } from '@lib/hooks/api-hooks'
import {
  clearCustomerStoredData,
  storeCustomerData,
  generateHaikunatorName,
} from './helpers'
import { ManagementClient } from '@lib/clients'
import { LOCAL_STORAGE_KEYS } from './consts'
import clsx from 'clsx'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import { setupGrpcClientTokenIfNeeded } from '@lib/helpers/setup-grpc-client-token'
import { GrpcClient } from '@lib/clients/grpc-client'

const managementClient = new ManagementClient()

interface TenantCreationError extends Error {
  code: number
}

function TenantCreationComplex() {
  const [tenantName, setTenantName] = useState('')
  const [customer, setCustomer] = useState<any | null>(null)
  const [isTenantLoading, setIsTenantLoading] = useState(false)
  const [error, setError] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const [
    tenantCreationDnsResolutionThreshold,
    setTenantCreationDnsResolutionThreshold,
  ] = useState(0)
  const [tenantCreationTimeoutThreshold, setTenantCreationTimeoutThreshold] =
    useState(0)

  const [agreement, setAgreement] = useState(false)

  const [isTtlTimeout, setIsTtlTimeout] = useState(false)

  const { elastioRegions, selectedRegion, setActiveRegion } =
    useFetchElastioRegions()

  const handleGenerateTenantName = () => {
    setTenantName(generateHaikunatorName())
  }

  async function fetchCustomerProfileAndRedirect() {
    try {
      await setupGrpcClientTokenIfNeeded(GrpcClient)
      const customerProfile = await managementClient.getCustomerProfile()
      setCustomer(customerProfile)

      handleGenerateTenantName()
    } catch (e) {
      return SystemHelper.pureNavigate(
        `${PagePathConstant.AUTH0_GLOBAL_LOGIN}?redirect=tenant-creation`
      )
    }
  }

  const getDomainName = () => {
    const PROD_DOMAIN = 'elastio.com'

    const isProd = window.location.hostname.includes(PROD_DOMAIN)

    return isProd ? '.app.elastio.com' : '.staging.elastio.us'
  }

  const handleRegionCheckboxChange = (
    event: SyntheticEvent<Element, Event>
  ) => {
    const { value } = event.target as HTMLInputElement

    setActiveRegion(value)
  }

  const handleTenantNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    const regExp = new RegExp(/^[a-z0-9]+(-[a-z0-9]+)*$/)

    setTenantName(value)

    setError('')

    if (value.length < 2 || value.length > 50) {
      setError('Tenant`s name is too short or too long.')
      return
    }

    if (!regExp.test(value)) {
      setError(
        'The tenant`s name does not fit the demanding pattern. Only lower case letters and numbers are allowed, hyphen only in the middle (not first or last) and is not consecutive'
      )
      return
    }
  }

  const checkTenantAvailability = async (
    redirectUrl: string,
    timeCreated?: number
  ) => {
    const storedCustomerData = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO) || ''
    )

    const currentTime = Date.now()
    const ttl = tenantCreationDnsResolutionThreshold * 1000

    const timeoutTtl = tenantCreationTimeoutThreshold * 1000

    const timeDiff =
      currentTime - (storedCustomerData.time_created ?? timeCreated)

    if (timeDiff > ttl) {
      SystemHelper.sendSentryIfProd(
        `The customer ${storedCustomerData?.customer?.email} can not reach his brand new tenant ${storedCustomerData.tenant_name}`
      )
    }

    if (timeDiff > timeoutTtl) {
      setIsTtlTimeout(true)
    }

    try {
      const result = await fetch(`${redirectUrl}/dashboard`, {
        credentials: 'include',
        cache: 'no-cache',
      })

      if (result.status === 200) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO)
        return window.location.replace(redirectUrl)
      }
    } catch (e) {
      logger.warn(
        'Can not resolve tenant DNS. Try to clean cache or use another browser.'
      )
      setTimeout(() => checkTenantAvailability(redirectUrl, timeCreated), 15000)
    }
  }

  const handleTenantCreation = async () => {
    if (error || !agreement) {
      return
    }

    const domainName = getDomainName()

    const redirectUrl = `https://${tenantName}${domainName}`

    setIsTenantLoading(true)

    storeCustomerData({
      tenant_name: tenantName,
      time_created: Date.now(),
      redirect_url: redirectUrl,
      customer,
    })

    try {
      await managementClient.createTenant({
        tenantName,
        elastioRegionName: selectedRegion,
      })
      await checkTenantAvailability(redirectUrl, Date.now())
    } catch (e: unknown) {
      setIsTenantLoading(false)
      if ((e as TenantCreationError).code === GrpcCodes.ALREADY_EXISTS) {
        setError('Tenant already exists')
      }
    }
  }

  async function fetchEnvs() {
    const result = await fetch('api/global/get-settings')

    const {
      loginUrl: loginLink,
      tenantCreationDnsResolutionThreshold:
        tenantCreationDnsResolutionThresholdResponse,
      tenantCreationTimeoutThreshold: tenantCreationTimeoutThresholdResponse,
    }: {
      loginUrl: string
      tenantCreationDnsResolutionThreshold: number
      tenantCreationTimeoutThreshold: number
    } = await result.json()

    setLoginUrl(loginLink)

    setTenantCreationDnsResolutionThreshold(
      tenantCreationDnsResolutionThresholdResponse
    )
    setTenantCreationTimeoutThreshold(tenantCreationTimeoutThresholdResponse)
  }

  useEffect(() => {
    fetchCustomerProfileAndRedirect()
    fetchEnvs()
  }, [])

  useEffect(() => {
    if (
      !(tenantCreationDnsResolutionThreshold && tenantCreationTimeoutThreshold)
    ) {
      return
    }

    const customerStoredInfo = localStorage.getItem(
      LOCAL_STORAGE_KEYS.CUSTOMER_INFO
    )
      ? JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO) as string
        )
      : null

    if (customerStoredInfo) {
      setIsTenantLoading(true)

      checkTenantAvailability(customerStoredInfo.redirect_url)
    }
  }, [tenantCreationDnsResolutionThreshold, tenantCreationTimeoutThreshold])

  const shouldShowRegionsBlock = elastioRegions && elastioRegions.length > 1

  const renderLoadingBlock = () => (
    <>
      <div className="loader"></div>
      <div className="loading_title">
        <img src="/images/logo-red.svg" alt="Logo" />
        <h1>elastio</h1>
      </div>
      <div className="loading_description">
        <h1 id="tenant_loading">Your Tenant is Loading...</h1>
        <p>
          Do not refresh the page. <br />
          It may take a few minutes to create the tenant.
        </p>
        <br />
        {isTtlTimeout && (
          <p>
            The loading of your tenant has taken more than{' '}
            {moment.duration(tenantCreationTimeoutThreshold * 1000).humanize()}.
            You can wait for the automatic redirect, or go back to the{' '}
            <span
              className="clearCustomerData"
              onClick={clearCustomerStoredData}
            >
              tenant creation page.
            </span>
          </p>
        )}
      </div>
    </>
  )

  const renderForm = () => (
    <>
      {customer?.email && <SignedAsHeader email={customer.email} />}
      <h1 className="select-tenant-name">Select tenant name</h1>
      <div className="create-tenant-input">
        <Tooltip
          placement="top"
          title="Regenerate tenant name"
          onClick={handleGenerateTenantName}
        >
          <RefreshIcon id="refresh-icon" color="secondary" />
        </Tooltip>
        <TextField
          className="tenant-field"
          onChange={handleTenantNameChange}
          required
          id="create-tenant-field"
          value={tenantName}
        />
        <p>{getDomainName()}</p>
      </div>
      {error && <div className="formGlobalError">{error}</div>}
      <div className="description-text">
        A tenant name is a sub-domain that allows you to access and use Elastio.
        We have pre-selected a tenant name for you, but you have the option to
        enter your own. Please note that duplicate names are not allowed.
      </div>
      {shouldShowRegionsBlock && (
        <React.Fragment>
          <div className="regions-block">
            <h1>Region</h1>
            {elastioRegions.map((region) => (
              <div
                className="region-block"
                key={region.customerFacingRegionName}
              >
                <FormControlLabel
                  onChange={handleRegionCheckboxChange}
                  control={
                    <Checkbox
                      value={region.regionName}
                      className="region-checkbox"
                      checked={region.regionName === selectedRegion}
                    />
                  }
                  label={`${region.regionName} (${region.customerFacingRegionName})`}
                />
              </div>
            ))}
          </div>
          <span id="host-tenants">
            We host all tenants in the us-east-2 region. We will add additional
            regions soon.
          </span>
        </React.Fragment>
      )}

      <div className="agreement-block">
        <Checkbox
          className="region-checkbox"
          checked={agreement}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setAgreement(event.target.checked)
          }
        />

        <span id="agreement">
          By checking this box you agree to the terms in the{' '}
          <a href="https://elastio.com/subscription-agreement/" target="_blank">
            subscription agreement.
          </a>
        </span>
      </div>

      <div
        className={clsx('create-tenant tenant-creation-page', {
          disabled: error || !agreement,
        })}
        role="button"
        onClick={handleTenantCreation}
      >
        Create a tenant
      </div>

      {!!loginUrl && (
        <div className="back-to-list">
          Not ready to create a new tenant?
          <Link href={loginUrl}>Go back to tenant list</Link>
        </div>
      )}
    </>
  )

  return (
    <div
      className={clsx('container', {
        container_loading: isTenantLoading,
      })}
    >
      <div className="form">
        {isTenantLoading ? renderLoadingBlock() : renderForm()}
      </div>
    </div>
  )
}

export default TenantCreationComplex
